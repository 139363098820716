import { ApiClient } from '../newPackages/ApiClient'

type NonBodyMethods = 'GET' | 'HEAD' | 'OPTIONS'
type BodyMethods = 'PUT' | 'PATCH' | 'DELETE' | 'POST'
type NonBodyRequestOptions = { method: NonBodyMethods }
type BodyRequestOptions = { method: BodyMethods; body?: object }
type AllRequestOptions = { url: string; query?: Record<string, string>; headers?: Record<string, string> }
const isBodyMethod = (
  options: AllRequestOptions & (NonBodyRequestOptions | BodyRequestOptions)
): options is AllRequestOptions & BodyRequestOptions => {
  return ['PUT', 'PATCH', 'DELETE', 'POST'].includes(options.method)
}

/**
 * Performs an asynchronous fetch request to the given URL with the specified method, body and headers.
 * @param {string} options.url - The URL to fetch from.
 * @param {Method} options.method - The HTTP method to use (e.g. GET, POST, PUT, DELETE).
 * @param {string} [options.body] - The optional request body to send along with the request.
 * @param {Record<string,string>} [options.headers] - The optional headers to set for the request.
 * @returns {Promise<Response>} A promise that resolves to a Response object containing the status, headers and body of the response.
 */
export const apiFetch = async (
  options: AllRequestOptions & (NonBodyRequestOptions | BodyRequestOptions)
): Promise<Response> => {
  return await ApiClient.convenientApiFetch(options)
}

type AuthenticatedRequestOptions = { token: string; templated?: boolean }

/**
 * Wrapper for compatibility
 */
export const authenticatedApiFetch = async (
  options: AllRequestOptions & (NonBodyRequestOptions | BodyRequestOptions) & AuthenticatedRequestOptions
): Promise<Response> => {
  return await ApiClient.convenientApiFetch(options)
}
