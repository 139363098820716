import { PlayerAPI, SubtitleTrack } from 'bitmovin-player'
import getConfig from 'next/config'
import { getDeviceIsXbox } from '../hooks/useDeviceInfo'
import { Logger } from './logger'

export const getBitmovinSubtitleTracks = (
  player: PlayerAPI
): {
  enabled: boolean
  textTracks: SubtitleTrack[]
  nextTrack?: SubtitleTrack
  activeTrack?: SubtitleTrack
} => {
  const textTracks = player.subtitles.list()
  const activeTrack = textTracks.find((track) => track?.enabled)
  const nextTrack = textTracks[0]

  return {
    enabled: activeTrack ? true : false,
    textTracks,
    nextTrack,
    activeTrack,
  }
}

/**
 * This is intended to be used for xboxes in devMode
 */
export const modifyDRMTokenIfApplicable = async (token: string) => {
  const { publicRuntimeConfig: config } = getConfig()
  const enabled = config?.localXboxDrmEnabled ?? false
  if (!getDeviceIsXbox() || !enabled) {
    return token
  }

  try {
    const response = await fetch('/api/drm', {
      method: 'POST',
      body: JSON.stringify({ token }),
    })
    const responseBody = (await response.json()) as { token: string }
    return responseBody.token ?? token
  } catch (ex) {
    Logger.of('VideoUtils').debug('Error handling local drm orchestration for xbox', {
      error: ex,
    })
    return token
  }
}
