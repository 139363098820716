import { Link, Video } from '@grandstand/presentation-models'
import { AppleFairplayDRMConfig, DRMConfig, HttpHeaders, SourceConfig, WidevineModularDRMConfig } from 'bitmovin-player'
import { NextRouter } from 'next/router'
import { Dispatch, SetStateAction, useContext, useEffect, useMemo, useState } from 'react'
import { authenticatedApiFetch } from '../apiClient/apiClient'
import { ConfigServiceContext, useContextUnconditionally } from '../services/config/ConfigService'
import { UserServiceContext } from '../services/user/UserService'
import { ErrorDetails, ErrorButton as VideoErrorButton } from '../type/Error'
import { isConnectedWeb } from '../utils/appUtils'
import { Logger } from '../utils/logger'
import { modifyDRMTokenIfApplicable } from '../utils/videoUtil'
import { getAdInfo, getDeviceIsSafari, getDeviceIsXbox, getDeviceType } from './useDeviceInfo'
import { getIsDebug } from './useIsDebug'
const logger = Logger.of('BitmovinVideoPlayer')
export type ConvivaVideoAnalytics = {
  [key: string]: any
}

export type VideoAnalytics = {
  default: Analytics
  conviva: ConvivaVideoAnalytics
}
export interface VideoSrc {
  videoId: string
  title: string
  src: string
  type: 'application/dash+xml' | 'application/x-mpegURL'
  isLive: boolean
  stream_type: 'linear' | 'vod'
  keySystems?: KeySystems
  heartbeat: Heartbeat
  analytics: Analytics
  conviva_analytics?: {
    [key: string]: any
  }
  raw: VideoResponse
}

export interface Heartbeat {
  interval: number
  link: Link
}

// All props are marked as optional since the payload may change depending
// on video type.
export interface VideoAnalyticsForGoogle {
  away_team?: string
  broadcast_team?: string
  content_region?: string
  content_type?: string
  first_air_date?: string
  first_air_time?: string
  first_air_timestamp?: string
  game_id?: string
  game_type?: string
  home_team?: string
  league?: string
  material_id?: string
  stream_type?: string
  streaming_method?: string
  team?: string
  video_duration?: number
  video_id?: string
  video_platform?: string
  video_title?: string
  video_type?: string
}

export interface Analytics extends VideoAnalyticsForGoogle {
  /* */
}

export interface HeartbeatResponse {
  message: string
}

export interface KeySystems {
  'com.widevine.alpha'?: DRMInfo
  'com.microsoft.playready'?: DRMInfo
  'com.apple.fps.1_0'?: any
}

export interface DRMInfo {
  url?: string
  licenseHeaders?: LicenseHeaders
}

export interface LicenseHeaders {
  'x-dt-auth-token'?: string
}

export interface VideoResponse {
  url: string
  title: string
  description: string
  islive: boolean
  stream_type: 'linear' | 'vod'
  drm?: DRM
  heart_beat: Heartbeat
  analytics: Analytics
  conviva_analytics?: {
    [key: string]: any
  }
  start_date: string
  end_date: string
  next_id?: string
  video: Video
}

export interface DRM {
  licenseUrl: string
  token: string
}

// ERROR types + classes
export type ButtonSize = 'min' | 'xs' | 'sm' | 'md' | 'lg'
export type ButtonStyle = 'primary' | 'alt' | 'on-image' | 'live'

// .video types

export type VideoState = {
  title: string
  url: string
  src: VideoSrc
  isLive: boolean
  keySystems?: KeySystems
  heartbeat?: Heartbeat
}
// .video DRM types
export interface DRM {
  licenseUrl: string
  token: string
}

export interface KeySystems {
  'com.widevine.alpha'?: DRMInfo
  'com.microsoft.playready'?: DRMInfo
  'com.apple.fps.1_0'?: any
}

export interface DRMInfo {
  url?: string
  licenseHeaders?: LicenseHeaders
}

export interface FairplayDRMInfo {
  licenseUri?: string
  certificateUri: string
  licenseHeaders: LicenseHeaders
  certificateHeaders: LicenseHeaders
}

export interface LicenseHeaders {
  'x-dt-auth-token'?: string
}

// heartbeat
export interface Heartbeat {
  interval: number
  link: Link
}

export interface HeartbeatResponse {
  message: string
}

export type VideoServiceProps = {
  videoId: string
  useRouter: () => NextRouter
  isDisabled?: boolean
}

export class VideoServiceError {
  status: number
  message: string
  code: string
  constructor({ message, status, code }: { message: string; status: number; code: string }) {
    this.message = message
    this.status = status
    this.code = code
  }
}

type VideoStatus = 'loading' | 'success' | 'error'

export type DRMType = 'widevine' | 'playready' | 'fairplay'
export type DRMFormat = 'DASH' | 'HLS'

export type BitmovinStore = {
  src: SourceConfig | null
  analytics: VideoAnalytics
  error: ErrorDetails | null
  status: VideoStatus
  setError: Dispatch<SetStateAction<ErrorDetails | null>>
}

export const useBitmovinVideo = ({ videoId, useRouter }: VideoServiceProps): BitmovinStore => {
  const router = useRouter()

  const { currentConfig, apiEnvironment, nativeAppVersion } = useContextUnconditionally(ConfigServiceContext)
  const { currentUser } = useContext(UserServiceContext)
  const [src, setSrc] = useState<SourceConfig | null>(null)

  const [analytics, setAnalytics] = useState<VideoAnalytics>({ default: {}, conviva: {} })
  const [error, setError] = useState<ErrorDetails | null>(null)

  const status = useMemo<VideoStatus>(() => {
    if (error) {
      return 'error'
    } else {
      return src ? 'success' : 'loading'
    }
  }, [error, src])

  useEffect(() => {
    if (error) {
      return
    }
    const getVideo = async () => {
      logger.debug('Getting video', { videoId })
      const configUrl = currentConfig?.API.services.video_services.playback
      const token = currentUser?.user_token
      if (configUrl === undefined || token === undefined) {
        return
      }
      const adInfo = await getAdInfo(apiEnvironment)
      const isSafari = getDeviceIsSafari()
      const isXbox = getDeviceIsXbox()
      const getUrl = (): string => {
        const baseUrl = `${configUrl}/${videoId}`

        const deviceType = getDeviceType()
        let drmType: DRMType = 'widevine'
        let format: string = 'DASH'
        switch (deviceType) {
          case 'tv_xboxone':
            logger.debug('Identified device as XBox', { videoId })
            drmType = 'playready'
            break
          case 'web_browser':
            logger.debug('Identified device as browser', { videoId })
            if (isSafari) {
              logger.debug('Identified device as Safari', { videoId })
              format = 'HLS'
              drmType = 'fairplay'
            }
            break
        }

        const params: { [key: string]: any } = {
          drmType,
          format,
          appversion: nativeAppVersion,
          debug: getIsDebug(),
          ...adInfo,
        }

        const favTeams = currentUser?.profile.favorites.teams

        if (favTeams && favTeams.length > 0) {
          params.favteams = favTeams.join(',')
        }

        if (isConnectedWeb()) {
          params.chromecast = true
        }
        if (params.did === '') {
          delete params.did
        }

        // convert params to string
        const paramsString = new URLSearchParams(params).toString()

        return `${baseUrl}?${paramsString}`
      }
      try {
        const res = await authenticatedApiFetch({
          url: getUrl(),
          token,
          method: 'GET',
        })

        if (res.ok) {
          const body = (await res.json()) as VideoResponse
          const {
            video: { title, description, thumbnail },
          } = body
          const getDrmConfig = async (): Promise<DRMConfig> => {
            const drm = body.drm
            if (!drm) {
              return {}
            }
            const { licenseUrl, token: originalToken } = drm
            const token = await modifyDRMTokenIfApplicable(originalToken)
            const LA_URL = licenseUrl
            const headers: HttpHeaders = {
              'x-dt-auth-token': token,
            }
            const getPlayReadyDRM = (): WidevineModularDRMConfig => {
              return {
                LA_URL,
                headers: {
                  ...headers,
                  'Content-Type': 'text/xml',
                },
                // videoRobustness: '150',
                // audioRobustness: '150',
                retryOtherKeysOnForbiddenLicense: true,
                keySystemPriority: ['com.microsoft.playready'],
                withCredentials: true,
              }
            }

            const getWidevineDRM = (): WidevineModularDRMConfig => {
              return {
                LA_URL,
                headers,
                keySystemPriority: ['com.widevine.alpha'],
                withCredentials: true,
              }
            }

            // broken on safari - need to fix
            const getFairplayDRM = (): AppleFairplayDRMConfig => {
              let certUrl = new URL(drm.licenseUrl)
              certUrl.pathname += 'cert/ballysports'

              return {
                LA_URL,
                certificateURL: certUrl.toString(),
                headers,
                certificateHeaders: headers,
                keySystemPriority: ['com.apple.fps.1_0', 'com.apple.fps'],
                prepareMessage: (event, session) => {
                  return 'spc=' + encodeURIComponent(event.messageBase64Encoded) + '&' + session.contentId
                },
                prepareContentId: (contentId) => {
                  const pattern = 'skd://drmtoday?'
                  let parameters
                  let idx = contentId.indexOf(pattern)

                  if (idx > -1) {
                    parameters = contentId.substring(idx + pattern.length)
                    parameters = parameters.replace(/assetid/gi, 'assetId')
                    parameters = parameters.replace(/variantid/gi, 'variantId')
                    return parameters
                  } else {
                    // return '' // og
                    return contentId
                  }
                },
              }
            }

            if (isSafari) {
              return {
                fairplay: getFairplayDRM(),
              }
            }

            if (isXbox) {
              return {
                playready: getPlayReadyDRM(),
              }
            }

            return {
              widevine: getWidevineDRM(),
            }
          }

          const nextSrc: SourceConfig = {
            dash: body.url,
            hls: body.url,
            // smooth: body.url,
            drm: await getDrmConfig(),
            poster: thumbnail,
            title,
            description,
          }

          const analytics: VideoAnalytics = {
            default: body?.analytics ?? {},
            conviva: body?.conviva_analytics ?? {},
          }
          setAnalytics(analytics)
          setSrc(nextSrc)
          setError(null)
        } else {
          const error = await res.json()
          logger.error('Video service error', { videoId, error })
          setError(error)
          setAnalytics({ default: {}, conviva: {} })
          setSrc(null)
        }
      } catch (error) {
        logger.error('Unable to get video', error)
        setError({ code: 'network_error' } as ErrorDetails)
        setSrc(null)
      }
    }

    // get video
    getVideo()
    return () => {
      /* */
    }
  }, [
    apiEnvironment,
    currentConfig?.API.services.video_services.playback,
    currentUser?.profile.favorites.teams,
    currentUser?.user_token,
    error,
    nativeAppVersion,
    videoId,
  ])

  return {
    analytics,
    src,
    status,
    error,
    setError,
  }
}
export { ErrorDetails, VideoErrorButton }
